$(document).ready(function () {

  // Timeline mobile
  function adjustTimelineMobile () {
    var windowWidth = $(window).width()

    if (windowWidth < 768) {
      var timelines = $('.timeline')

      timelines.each(function (index, timeline) {
        var timelineItems = $(timeline).find('.timeline-items')
        var timelineActive = timelineItems.find('.timeline-item.active')

        if (timelineActive.length > 0) {
          var timelineActiveWidth = timelineActive.width()
          var timelineActiveLeft = timelineActive.offset().left
          var scrollLeft = (timelineActiveLeft + timelineActiveWidth / 2) - windowWidth / 2
          console.log(scrollLeft)
          timelineItems.scrollLeft(scrollLeft)
        }
      })
    }
  }

  adjustTimelineMobile()

  // Simulation
  var clientsList = []

  function addFractionateCard (clients) {
    var submitButton = $('#order-fractionate button[type="submit"]')
    var orderFractionateContainer = $('#order-fractionate #fractionate-order-container')
    var cardFractionateModel = $('#order-fractionate #card-fractionate-model')
    var cardsContainer = $('#order-fractionate #fractionate-order-cards')

    submitButton.prop('disabled', false)
    orderFractionateContainer.show()

    var card = cardFractionateModel.clone().html()
    var template = '<div class="col-lg-6">' + card + '</div>'

    cardsContainer.append(template)
  }

  // Simulation
  function removeFractionateCard (clients) {
    var submitButton = $('#order-fractionate button[type="submit"]')
    var orderFractionateContainer = $('#order-fractionate #fractionate-order-container')
    var cardsContainer = $('#order-fractionate #fractionate-order-cards')

    if (clients && clients.length > 0) {
      cardsContainer.find('.col-lg-6:last').remove();
    } else {
      submitButton.prop('disabled', true)
      orderFractionateContainer.hide()
      cardsContainer.empty()
    }
  }

  // Fractionate on change (simulation)
  $("#order-fractionate #order-fractionate-select").on("change", function (e) {
    var clients = $(this).val();

    if (clients.length > clientsList.length) {
      addFractionateCard(clients)
    } else {
      removeFractionateCard(clients)
    }

    clientsList = clients
  })

  // Fractionate on submit (simulation)
  $(document).on("submit", "#order-fractionate #order-fractionate-form", function (e) {
    e.preventDefault()
    window.location.href = 'pedido-resumo.html'
  })

  // On finish order
  $(document).on("click", "#finish-order", function () {
    window.modalHandler.create({
      title: "Sucesso!",
      message: "Seu pedido foi registrado com sucesso.<br/> Aguarde aprovação.",
      type: "success",
    });

    setTimeout(function () {
      window.location.href = "meus-pedidos.html";
    }, 4000);
  });
});
