$(document).ready(function () {
  // Variables
  var loginBox = $("#login-box");
  var forgotPasswordBox = $("#forgot-password-box");

  // Go to forgot password form
  $(document).on("click", "#go-to-forgot-password", function () {
    loginBox.fadeOut(500, function () {
      forgotPasswordBox.fadeIn();
    });
  });

  // Go to login form
  $(document).on("click", "#go-to-login", function () {
    forgotPasswordBox.fadeOut(500, function () {
      loginBox.fadeIn();
    });
  });
});
