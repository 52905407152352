// Handle with current year
function setCurrentYear () {
  var currentYearTarget = $(".current-year");
  var currentDate = new Date();

  if (!currentYearTarget) return;

  currentYearTarget.text(currentDate.getFullYear());
}

function initTooltips () {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="tooltip"]').on("show.bs.tooltip", function () {
    var target = $(this);
    var customClass = target.data("custom-class");
    if (customClass) {
      $(target.data("bs.tooltip").tip).addClass(customClass);
    }
  });
}

function setContentLoaded () {
  setTimeout(function () {
    $('body').addClass('loaded')
  }, 300)
}

$(document).ready(function () {
  // Set current year
  setCurrentYear();

  // Init tooltips
  initTooltips();

  // Set content loaded
  setContentLoaded()
});
