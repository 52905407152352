$(document).ready(function () {
  // Select2 init
  function select2Init (el) {
    var target = $(el);
    var config = {
      placeholder: "Selecionar",
      language: "pt-BR",
      dropdownCssClass: "select2-component-dropdown",
    };

    if (target.data("disable-search")) {
      config.minimumResultsForSearch = Infinity;
    }

    $(el).select2(config);
  }

  // Get all selects and init select2
  var selects = $(".select2-component select");

  selects.each(function (index, el) {
    select2Init(el);
  });

  selects.on("select2:close", function (e) {
    try {
      $(this).valid();
    } catch (error) { }
  });

  // Datepicker init
  $('.datepicker').datepicker({
    format: 'dd/mm/yyyy',
    autoclose: true,
    assumeNearbyYear: true,
    clearBtn: true,
    forceParse: false,
    language: 'pt-BR',
    orientation: 'bottom',
    todayHighlight: true
  });

  // Input on focus
  $(document).on("focus", ".form-control", function () {
    var target = $(this);
    var parent = target.closest(".form-group");
    parent.addClass("active");
  });

  // Input on blur
  $(document).on("blur", ".form-control", function () {
    var target = $(this);
    var parent = target.closest(".form-group");
    parent.removeClass("active");
  });

  // Password reveal
  $(document).on("click", ".form-password-toggle", function () {
    var target = $(this);
    var parent = target.closest(".form-password");
    var revealed = parent.hasClass("revealed");
    var revealIcon = target.find(".icon");
    var input = parent.find(".form-control");

    if (revealed) {
      input.prop("type", "password");
      revealIcon.text("visibility");
    } else {
      input.prop("type", "text");
      revealIcon.text("visibility_off");
    }

    parent.toggleClass("revealed");
  });

  // Quantity on blur
  $(document).on("blur", ".form-quantity-input", function () {
    var target = $(this);
    var inputValue = parseInt(target.val(), 10);
    if (isNaN(inputValue) || inputValue < 0) {
      target.val(0);
      target.trigger("input");
    }
  });

  // Quantity decrease
  $(document).on("click", ".form-quantity-down", function () {
    var target = $(this);
    var parent = target.closest(".form-quantity");
    var input = parent.find(".form-quantity-input");
    var inputValue = parseInt(input.val(), 10);
    if (inputValue >= 1) {
      inputValue = isNaN(inputValue) ? 0 : inputValue;
      inputValue--;
      input.val(inputValue);
      input.trigger("input");
    }
  });

  // Quantity increase
  $(document).on("click", ".form-quantity-up", function () {
    var target = $(this);
    var parent = target.closest(".form-quantity");
    var input = parent.find(".form-quantity-input");
    var inputValue = parseInt(input.val(), 10);
    inputValue = isNaN(inputValue) ? 0 : inputValue;
    inputValue++;
    input.val(inputValue);
    input.trigger("input");
  });

  // Masks
  var phoneMaskBehavior = function (val) {
    return val.replace(/\D/g, "").length === 11
      ? "(00) 00000-0000"
      : "(00) 0000-00009";
  },
    phoneOptions = {
      onKeyPress: function (val, e, field, options) {
        field.mask(phoneMaskBehavior.apply({}, arguments), options);
      },
    };

  $(".cpf").mask("000.000.000-00", { reverse: true });
  $(".cnpj").mask("00.000.000/0000-00", { reverse: true });
  $(".date").mask("00/00/00000");
  $(".phone").mask(phoneMaskBehavior, phoneOptions);

  // Login form validation
  $("#login-form").validate({
    ignore: ".ignore",

    rules: {
      username: {
        required: true,
      },
      password: {
        required: true,
        minlength: 6,
      },
    },

    messages: {},

    errorElement: "em",

    errorPlacement: function (error, element) {
      // Add the `invalid-feedback` class to the error element
      error.addClass("invalid-feedback");

      if (element.prop("type") === "checkbox") {
        error.insertAfter(element.next("label"));
      } else {
        error.insertAfter(element);
      }
    },

    submitHandler: function (form, event) {
      event.preventDefault();

      console.log("form: ", $(form).serialize());

      window.location.href = "inicio.html";
    },
  });

  // Register form validation
  $("#register-form").validate({
    ignore: ".ignore",

    rules: {
      cnpj: {
        required: true,
        cnpjBR: true,
      },
      social_reason: {
        required: true,
      },
      fantasy_name: {
        required: true,
      },
      username: {
        required: true,
      },
      email: {
        required: true,
        email: true,
      },
      phone: {
        required: true,
        minlength: 14,
      },
      cellphone: {
        required: true,
        minlength: 14,
      },
      password: {
        required: true,
        minlength: 6,
      },
      password_confirmation: {
        required: true,
        minlength: 6,
        equalTo: "#password",
      },
      terms: "required",
      hiddenRecaptcha: {
        required: function () {
          if (grecaptcha.getResponse() == "") {
            return true;
          } else {
            return false;
          }
        },
      },
    },

    messages: {
      phone: {
        minlength: "Por favor, preencha o telefone corretamente!",
      },
      cellphone: {
        minlength: "Por favor, preencha o celular corretamente!",
      },
      terms:
        "Por favor, aceite nossos termos de uso para prosseguir com o cadastro!",
      hiddenRecaptcha: {
        required: "Por favor, realize a validação do reCAPTCHA!",
      },
    },

    errorElement: "em",

    errorPlacement: function (error, element) {
      // Add the `invalid-feedback` class to the error element
      error.addClass("invalid-feedback");

      if (element.prop("type") === "checkbox") {
        error.insertAfter(element.next("label"));
      } else {
        error.insertAfter(element);
      }
    },

    submitHandler: function (form, event) {
      event.preventDefault();

      console.log("form: ", $(form).serialize());

      window.modalHandler.create({
        title: "Cadastro realizado com sucesso!",
        message:
          "Seu cadastro foi enviado para análise e em breve você receberá um retorno em seu e-mail com a confirmação.",
        type: "success",
      });

      setTimeout(function () {
        window.location.href = "index.html";
      }, 6000);
    },
  });

  // Profile form validation
  $("#profile-form").validate({
    ignore: ".ignore",

    rules: {
      cnpj: {
        required: true,
        cnpjBR: true,
      },
      social_reason: {
        required: true,
      },
      fantasy_name: {
        required: true,
      },
      username: {
        required: true,
      },
      email: {
        required: true,
        email: true,
      },
      phone: {
        required: true,
        minlength: 14,
      },
      cellphone: {
        required: true,
        minlength: 14,
      },
    },

    messages: {
      phone: {
        minlength: "Por favor, preencha o telefone corretamente!",
      },
      cellphone: {
        minlength: "Por favor, preencha o celular corretamente!",
      },
    },

    errorElement: "em",

    errorPlacement: function (error, element) {
      // Add the `invalid-feedback` class to the error element
      error.addClass("invalid-feedback");

      if (element.prop("type") === "checkbox") {
        error.insertAfter(element.next("label"));
      } else {
        error.insertAfter(element);
      }
    },

    submitHandler: function (form, event) {
      event.preventDefault();

      console.log("form: ", $(form).serialize());

      window.location.href = "meus-dados.html";
    },
  });

  // Password forgot form validation
  $("#password-forgot-form").validate({
    ignore: ".ignore",

    rules: {
      email: {
        required: true,
        email: true,
      },
    },

    messages: {},

    errorElement: "em",

    errorPlacement: function (error, element) {
      // Add the `invalid-feedback` class to the error element
      error.addClass("invalid-feedback");

      if (element.prop("type") === "checkbox") {
        error.insertAfter(element.next("label"));
      } else {
        error.insertAfter(element);
      }
    },

    submitHandler: function (form, event) {
      event.preventDefault();

      console.log("form: ", $(form).serialize());

      window.location.href = "index.html";
    },
  });

  // New password form validation
  $("#password-new-form").validate({
    ignore: ".ignore",

    rules: {
      password: {
        required: true,
        minlength: 6,
      },
      password_confirmation: {
        required: true,
        minlength: 6,
        equalTo: "#password",
      },
    },

    messages: {
      password_confirmation: {
        equalTo:
          "O campo confirmar senha precisa ser igual ao campo senha ao lado!",
      },
    },

    errorElement: "em",

    errorPlacement: function (error, element) {
      // Add the `invalid-feedback` class to the error element
      error.addClass("invalid-feedback");

      if (element.prop("type") === "checkbox") {
        error.insertAfter(element.next("label"));
      } else {
        error.insertAfter(element);
      }
    },

    submitHandler: function (form, event) {
      event.preventDefault();

      console.log("form: ", $(form).serialize());

      window.location.href = "index.html";
    },
  });

  // Change password form validation
  $("#password-change-form").validate({
    ignore: ".ignore",

    rules: {
      password: {
        required: true,
        minlength: 6,
      },

      new_password: {
        required: true,
        minlength: 6,
      },

      new_password_confirmation: {
        required: true,
        minlength: 6,
        equalTo: "#new_password",
      },
    },

    messages: {},

    errorElement: "em",

    errorPlacement: function (error, element) {
      // Add the `invalid-feedback` class to the error element
      error.addClass("invalid-feedback");

      if (element.prop("type") === "checkbox") {
        error.insertAfter(element.next("label"));
      } else {
        error.insertAfter(element);
      }
    },

    submitHandler: function (form, event) {
      event.preventDefault();
      console.log("form: ", $(form).serialize());
    },
  });
});
