$(document).ready(function () {
  $(document).on("click", ".menu-navigation a", function (event) {
    event.preventDefault();

    var target = $(this);
    var targetIsActive = target.hasClass("active");

    if (!targetIsActive) {
      var allMenuItems = $(".menu-navigation a");
      allMenuItems.removeClass("active");

      var allSections = $(".menu-section");
      allSections.removeClass("active");

      var href = target.attr("href");
      var hash = href.substr(href.indexOf("#"));
      var section = $(hash);

      target.addClass("active");
      section.addClass("active");
    }
  });
});
